@import url('https://use.typekit.net/dww8qmh.css');

body {
  margin: 0;
  font-family: 'Baskerville URW';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececed
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
