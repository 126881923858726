.container-component {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.container-component .title-text {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.container-component .link-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    height:20px;
    margin-top: 8px;
}

.link-container:hover {
    opacity: 0.6;
    /* color: red; */
}

.container-component .link-container .icon {
    margin-left: 8px;
}

.container-component .test {
    display: flex;
    justify-content: center;
    align-items: center;
  }